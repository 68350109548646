import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from "react-router-dom";
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import * as link from './link';
import { primaryColorVar } from './reactiveVariables';
import { PublicLayout } from './layouts/public';
import { PrivateLayout } from './layouts/private';

import './App.scss';

export const history = createBrowserHistory();

const client = new ApolloClient({
  link: ApolloLink.from([
    //link.errorLink,
    link.authLink,
    link.httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          PrimaryColor: {
            read() {
              return primaryColorVar();
            },
          },
        },
      },
    },
  }),

  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});

function App() {
  useEffect(() => {
    document.title = 'Loading...';
  }, [])

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <Switch>
          <Route path="/auth/*" component={PublicLayout} />
          <Route path="/*" component={PrivateLayout} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

//export default withAuthContext(App);
export default App;
