import jwtDecode from 'jwt-decode';

const STORE_RELEASE = 6;

function getWithVersionCheck(key: string) {
  const lStore = localStorage.getItem(key);
  if (lStore === null) {
    return null;
  }
  let rr;

  try {
    rr = JSON.parse(lStore);
  } catch (err) {
    if (!(err instanceof SyntaxError)) {
      throw err;
    }
    return null;
  }
  if (rr.release !== STORE_RELEASE) {
    return null;
  }
  if (!rr.payload) {
    return null;
  }
  return rr.payload;
}

function setWithVersionCheck(key: string, payload: any) {
  localStorage.setItem(key, JSON.stringify({ payload, release: STORE_RELEASE }));
}

const TOKEN_KEY = "apptoken";

export const setAuthTokens = ({ jwtBearer, jwtRefresh } : { jwtBearer: string, jwtRefresh: string }) =>
  setWithVersionCheck(TOKEN_KEY, JSON.stringify({ jwtBearer, jwtRefresh }));

export const setToken = (jwtBearer: string) => {
  const pp = JSON.parse(getWithVersionCheck(TOKEN_KEY));
  pp.jwtBearer = jwtBearer;
  setWithVersionCheck(TOKEN_KEY, JSON.stringify(pp));
};

export const getToken = () => {
  const pp = JSON.parse(getWithVersionCheck(TOKEN_KEY));
  return pp && pp.jwtBearer;
};

export const getAllTokens = () => {
  const pp = JSON.parse(getWithVersionCheck(TOKEN_KEY));
  return pp;
};

export const getRefresh = () => {
  const pp = JSON.parse(getWithVersionCheck(TOKEN_KEY));
  return pp && pp.jwtRefresh;
};

export const clearToken = () => localStorage.removeItem(TOKEN_KEY);

const PROFILE_KEY = "appprofile";

export const setProfile = (user: any) =>
  setWithVersionCheck(PROFILE_KEY, JSON.stringify(user));

export const getProfile = () => JSON.parse(getWithVersionCheck(PROFILE_KEY));

export const clearProfile = () => localStorage.removeItem(PROFILE_KEY);

export const isJwtValid = () => {
  const jwtBearer = getToken();
  if(!jwtBearer) {
    return false;
  }
  const decoded: any = jwtDecode(jwtBearer);
  const now = Date.now() / 1000;
  if (decoded.exp < now) {
    return false;
  }

  return true;
}
