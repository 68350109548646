import React, { FC } from 'react';
import { HeaderAvatar } from './components/Avatar';
import { Menu } from './components/Menu';

import styles from './Header.module.scss';

export const Header: FC = () => (
  <div className={styles['container']}>
    <div className={styles['header']}>
      <Menu />
      <HeaderAvatar />
    </div>
  </div>
);
