import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Switch, Route, useHistory } from "react-router-dom";

import routes from '../../routes';
import { isJwtValid } from '../../utils/Auth.utils';
import { Header } from '../../components/header';
import Footer from '../../components/Footer';
import Spin from '../../components/Spin';

const SETTING = gql`
  query Setting {
    Setting {
      id
      siteName
      blogMenuPlacement
      favicon {
        url
      }
    }
  }
`;

export const PrivateLayout = () => {
  const { data: settingData } = useQuery(SETTING, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const setting = data?.Setting?.[0]
      const title = setting?.siteName ?? 'Website' ;
      document.title = title;

      if(setting?.favicon?.url) {
        const faviconEle = document.getElementById('favicon') as HTMLLinkElement;
        if(faviconEle) {
          faviconEle.href = setting?.favicon?.url;
        }
      }

    }
  });

  const history = useHistory();
  const title = settingData?.Setting?.[0]?.siteName ?? 'Website' ;
  document.title = title;

  if(!isJwtValid()) {
    history.push(routes.login.path);
  }

  return (
    <>
      <Header />
      <React.Suspense fallback={<Spin />}>
        <Switch>
          <Route exact path={routes.dashboard.path} component={routes.dashboard.component} />
          <Route path={routes.generalDisplay.path} component={routes.generalDisplay.component} />
          <Route path={routes.pages.path} component={routes.pages.component} />
          <Route exact path={routes.blog.path} component={routes.blog.component} />
          <Route path={routes.blogCreate.path} component={routes.blogCreate.component} />
          <Route path={routes.blogUpdate.path(':id')} component={routes.blogUpdate.component} />
          <Route exact path={routes.jobPost.path} component={routes.jobPost.component} />
          <Route path={routes.jobPostCreate.path} component={routes.jobPostCreate.component} />
          <Route path={routes.jobPostUpdate.path(':id')} component={routes.jobPostUpdate.component} />
          <Route path={routes.jobApplication.path} component={routes.jobApplication.component} />
          <Route exact path={routes.users.path} component={routes.users.component} />
          <Route path={routes.userCreate.path} component={routes.userCreate.component} />
          <Route path={routes.userUpdate.path(':id')} component={routes.userUpdate.component} />
        </Switch>
      </React.Suspense>
      <Footer />
    </>
  )
}
