import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Switch, Route, useHistory } from "react-router-dom";

import routes from '../../routes';
import { isJwtValid } from '../../utils/Auth.utils';
import Spin from '../../components/Spin';

const SETTING = gql`
  query Setting {
    Setting {
      id
      blogMenuPlacement
      siteName
      favicon {
        url
      }
    }
  }
`

export const PublicLayout = () => {
  useQuery(SETTING, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const setting = data?.Setting?.[0]
      const title = setting?.siteName ?? 'Website' ;
      document.title = title;

      if(setting?.favicon?.url) {
        const faviconEle = document.getElementById('favicon') as HTMLLinkElement;
        if(faviconEle) {
          faviconEle.href = setting?.favicon?.url;
        }
      }

    }
  });

  const history = useHistory();

  if(isJwtValid()) {
    history.push(routes.dashboard.path);
  }

  return (
    <>
      <React.Suspense fallback={<Spin />}>
        <Switch>
          <Route path={routes.login.path} component={routes.login.component} />
        </Switch>
      </React.Suspense>
    </>
  )
}

export default PublicLayout;
